import { useState } from "react";
import image1 from "../../components/images/home-background.png";
import { Button1 } from "../../components/button/Buttons";

const useHome = (dataSource) => {

    const items = [
        {
          src: image1,
          type: "image",
          content: (
            <div className="image-1 image-1-content">
              <div className="left-container">
                <p1>HillGro</p1>
                <p2>Agriculture Technology & Development</p2>
                <div className="learn-more">
                  <Button1 label={"Learn More"} />
                </div>
              </div>
              <div className="social-media"></div>
            </div>
          ),
          displayTime: 30,
          transparency: 0.5,
        },
        {
          src: "https://via.placeholder.com/600x300",
          type: "image",
          content: (
            <div className="image-1 image-1-content">
              <div className="left-container">
                <p1>HillGro</p1>
                <p2>Agriculture Technology & Development</p2>
                <div className="learn-more">
                  <Button1 label={"Learn More"} />
                </div>
              </div>
              <div className="social-media"></div>
            </div>
          ),
          displayTime: 5,
          transparency: 0.1,
        },
        {
          src: "https://www.w3schools.com/html/mov_bbb.mp4",
          type: "video",
          content: (
            <div className="image-1 image-1-content">
              <div className="left-container">
                <p1>HillGro</p1>
                <p2>Agriculture Technology & Development</p2>
                <div className="learn-more">
                  <Button1 label={"Learn More"} />
                </div>
              </div>
              <div className="social-media"></div>
            </div>
          ),
          transparency: 0.5,
        },
      ];
    
      const items2 = [
        {
          title: "#Smart Farming",
          description:
            "Leverage the power of technology with smart farming solutions. From IoT sensors to AI-driven analytics, optimize your crop yields and minimize waste.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
        {
          title: "#Drones in Agriculture",
          description:
            "Drones are transforming the agriculture industry, enabling farmers to monitor crop health, soil conditions, and more, all from the sky.",
          src: "https://via.placeholder.com/600x400?text=Drones+in+Agriculture+Image",
          type: "image",
        },
        {
          title: "#AI Crop Prediction",
          description:
            "Utilize Artificial Intelligence to predict crop yields, pest infestations, and weather patterns, ensuring smarter, more efficient farming decisions.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
        {
          title: "#Precision Agriculture",
          description:
            "Precision agriculture uses GPS and data analytics to provide farmers with detailed insights into soil conditions, irrigation, and planting strategies for better crop management.",
          src: "https://via.placeholder.com/600x400?text=Precision+Agriculture+Image",
          type: "image",
        },
        {
          title: "#AgriTech Solutions",
          description:
            "Explore cutting-edge AgriTech innovations such as automated machinery, mobile apps for farm management, and advanced irrigation systems to boost efficiency and sustainability.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
        {
          title: "#Blockchain for Supply Chain",
          description:
            "Blockchain technology is improving transparency in the agricultural supply chain, from farm to fork, ensuring fair trade and reducing fraud in the food industry.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
        {
          title: "#AgriTech Future",
          description:
            "Discover the future of farming with robotic harvesters, AI-driven irrigation, and blockchain technology in agriculture that ensures transparency.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
        {
          title: "#Automated Farm Machinery",
          description:
            "Watch the evolution of automated farm machinery that can plant, irrigate, and harvest crops with minimal human intervention, making farming more efficient.",
          src: "https://www.w3schools.com/html/mov_bbb.mp4", // Video URL
          type: "video",
        },
      ];
    
      const items3 = [
        {
          date: "Monday, November 20, 2024",
          description: "Farmers in India embrace AI technology to boost crop yields and reduce water usage",
          link: "https://example.com/article1"
        },
        {
          date: "Tuesday, November 21, 2024",
          description: "Government launches new subsidies for organic farming to encourage sustainable practices",
          link: "https://example.com/article2"
        },
        {
          date: "Wednesday, November 22, 2024",
          description: "Innovative drone technology helps farmers monitor crop health and improve pesticide application",
          link: "https://example.com/article3"
        },
        {
          date: "Thursday, November 23, 2024",
          description: "India sees rise in agri-tech startups offering solutions for better irrigation systems",
          link: "https://example.com/article4"
        },
        {
          date: "Friday, November 24, 2024",
          description: "Agri-business partnerships provide affordable equipment for smallholder farmers in rural areas",
          link: "https://example.com/article5"
        }
      ];
      

    return {
        items,
        items2,
        items3
    };
};

export default useHome;
