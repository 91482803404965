import React, { useState, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';


// Development --------------------------------------------------------------------
const hostname = window.location.hostname; 
export const server = `http://${hostname}:5000/api/v1/`;


export const Context = createContext({
  isAuthenticatedExpert: false,
  isAuthenticatedAdmin: false,
  user: {},
  userCurrentTimeZone: '',
});

const AppWrapper = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  return (
    <Context.Provider
      value={{
        user, setUser, isAuthenticated, setIsAuthenticated
      }}
    >
      <App />
    </Context.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper />
   </React.StrictMode>
);
