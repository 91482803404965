import React from "react";
import "./Buttons.css";

const Button1 = ({
  label,
  onClick,
  buttonStyle,
  span1Style,
  span2Style,
  arrowStyle,
}) => {
  return (
    <button className="button-1" onClick={onClick} style={buttonStyle}>
      {label}
      <span className="span-1" style={span1Style}></span>
      <span className="span-2" style={span2Style}></span>
      <div class="arrow" style={arrowStyle}></div>
    </button>
  );
};

const Button2 = ({ label, onClick, className, style }) => {
  return (
    <button onClick={onClick} className="button-2" style={style}>
      {label}
    </button>
  );
};

const Button3 = ({ label, onClick, className, style }) => {
  return (
    <button onClick={onClick} className="button-3" style={style}>
      {label}
    </button>
  );
};

export { Button1, Button2, Button3 };
