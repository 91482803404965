import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Dropdown from "../dropdown/Dropdown.jsx";
import useIcons from "../icons/icons.js";

const Header = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Listen for window resize events to dynamically change isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navItems = [
    {
      to: "/",
      label: "Home",
      key: "Home",
    },
    {
      label: "Solutions",
      key: "dropdown",
      dropdown: true,
    },
    {
      to: "/blog",
      label: "Blog",
      key: "Blog",
    },
    {
      to: "/contact",
      label: "Contact us",
      key: "Contact us",
    },
  ];

  const options = [
    { label: "Specilization", value: "Specilization" },
    { label: "Services", value: "Services" },
    { label: "Partners & Clients", value: "Partners-Clients" },
    { label: "Gallery", value: "Gallery" },
  ];

  const renderNavItem = ({ to, label, key, dropdown }) => {
    if (dropdown && !isMobile) {
      return (
        <div className="nav-item-container" key={key}>
          <Dropdown
            options={options}
            placeholder="Solutions"
            onSelect={(option) => {
              const linkTo = option ? `/${option.value.toLowerCase()}` : "/";
              navigate(linkTo);
            }}
            initialSelected={null}
            isHeaderDropdownProp={true}
          />
        </div>
      );
    } else if (dropdown && isMobile) {
      return (
        <div
          className="nav-phone-dropdown"
          key={key}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`nav-phone-dropdown-header ${
              isOpen && "active-header-dropdown-phone"
            }`}
          >
            {label}
            {isOpen ? <useIcons.upArrow /> : <useIcons.downArrow />}
          </div>
          {isOpen && (
            <>
              {options.map((option) => (
                <Link
                  to={`/${option.value.toLowerCase()}`}
                  className="nav-item-phone-dropdown"
                  key={option.value}
                  onClick={() => setOpenMenu(false)}
                >
                  {option.label}
                </Link>
              ))}
            </>
          )}
        </div>
      );
    }

    return (
      <div
        className={`nav-item-container ${
          activeTab === key ? "active-main-header" : ""
        }`}
        onClick={() => {setActiveTab(key); setOpenMenu(false)}}
        key={key}
      >
        <Link to={to} className={`nav-item`}>
          {label}
        </Link>
      </div>
    );
  };

  return (
    <>
      <nav className={`header-nav non-pnone-nav`}>
        <div className="nav-logo">
          <img src={useIcons.logo} alt="Logo" className="nav-icon" />
          {"HillGro"}
        </div>
        <article>{navItems.map(renderNavItem)}</article>
      </nav>
      <nav className={`header-nav pnone-nav`}>
        <div className="nav-menu">
          <div className="nav-logo">
            <img src={useIcons.logo} alt="Logo" className="nav-icon" />
            {"HillGro"}
          </div>
          {openMenu ? (
            <useIcons.close onClick={() => setOpenMenu(false)} />
          ) : (
            <useIcons.menu onClick={() => setOpenMenu(true)} />
          )}
        </div>
        {openMenu && <article>{navItems.map(renderNavItem)}</article>}
      </nav>
    </>
  );
};

export default Header;
