import React, { useState, useRef, useEffect } from "react";
import "./Carousel2.css";

const Carousel2 = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [deviceType, setDeviceType] = useState(null);
  const [transformValue, setTransformValue] = useState(60);
  const [cardHeight, setCardHeight] = useState(40);

  useEffect(() => {
    const updateTransformValue = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 600) {
        setTransformValue(90);
        setCardHeight(70);
        setDeviceType("mobile");
      } else if (screenWidth <= 1200) {
        setTransformValue(80);
        setCardHeight(35);
        setDeviceType("tablet");
      } else {
        setTransformValue(60);
        setCardHeight(40);
        setDeviceType("desktop");
      }
    };
    updateTransformValue();
    window.addEventListener("resize", updateTransformValue);
    return () => window.removeEventListener("resize", updateTransformValue);
  }, []);

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    // Optionally, you can handle autoplay or video loading here
  }, [currentIndex]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const delta = touchStartX.current - touchEndX.current;
    if (delta > 50) {
      nextItem();
    } else if (delta < -50) {
      prevItem();
    }
  };

  const handleVideoEnded = () => {
    nextItem();
  };

  return (
    <div
      className="carousel2-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="cards"
        style={{ transform: `translateX(-${currentIndex * transformValue}vw)` }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={`card${index === currentIndex ? " active" : ""}`}
            style={{
              width: `${transformValue}vw`,
              height: `${cardHeight}vh`,
              transform: `translateX(${
                (index - currentIndex + (deviceType === "mobile" ? 0.5 : deviceType === "tablet" ? 1 : 2.1)) *
                10
              }vw)`,
              zIndex: index === currentIndex ? 10 : 1,
              opacity: index === currentIndex ? 1 : 0.7,
              transition: "transform 0.5s ease, opacity 0.3s ease",
            }}
          >
            <div className="content">
              <div
                className="content-item left-content"
                style={{
                  backgroundImage: `url(${
                    item?.type === "image" ? item.src : ""
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {item?.type === "video" && (
                  <video
                    ref={videoRef}
                    autoPlay
                    muted
                    src={item.src}
                    onEnded={handleVideoEnded}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      // objectPosition: `${deviceType == "mobile" ? "top":"left"}`,
                      objectPosition:"center",
                      objectFit: "cover",
                      zIndex: -1,
                    }}
                  />
                )}
              </div>
              <div className="content-item right-content">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Indicators */}
      <div className="indicators">
        {items.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel2;
