// Import icons from different libraries
import { MdOutlineAttachEmail, MdOutlineContactPhone } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { SlSocialLinkedin } from "react-icons/sl";
import { IoIosNotifications } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import { SiGoogleadsense } from "react-icons/si";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import iconLogo from "../images/logo.png"
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

const useIcons = {
    email: MdOutlineAttachEmail,
    phone: MdOutlineContactPhone,
    location: GrMapLocation,
    linkedin: SlSocialLinkedin,
    notification:IoIosNotifications,
    search:GoSearch,
    googleAds: SiGoogleadsense,
    downArrow:IoIosArrowDown,
    upArrow:IoIosArrowUp,
    logo:iconLogo,
    menu:IoMenu,
    close:IoClose,
};

export default useIcons;
