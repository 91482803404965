import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css";

const Carousel = ({ items, autoPlayInterval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const intervalRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    items.forEach((item) => {
      if (item.type === "image") {
        const img = new Image();
        img.src = item.src;
      } else if (item.type === "video") {
        const video = document.createElement("video");
        video.src = item.src;
        video.load();
      }
    });
  }, [items]);

  const nextItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevItem = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const startAutoPlay = () => {
    const currentItem = items[currentIndex];
    const timeToDisplay = currentItem.displayTime || autoPlayInterval;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(nextItem, timeToDisplay * 1000);
  };

  const handleVideoEnded = () => {
    nextItem();
  };

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    if (items[currentIndex]?.type === "video" && videoRef.current) {
      videoRef.current.addEventListener("loadeddata", handleVideoLoaded);
      videoRef.current.addEventListener("ended", handleVideoEnded);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    } else {
      startAutoPlay();
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadeddata", handleVideoLoaded);
        videoRef.current.removeEventListener("ended", handleVideoEnded);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [currentIndex, items]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const delta = touchStartX.current - touchEndX.current;
    if (delta > 50) {
      nextItem();
    } else if (delta < -50) {
      prevItem();
    }
  };

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="carousel-content"
        style={{
          backgroundImage: `url(${
            items[currentIndex]?.type === "image" ? items[currentIndex].src : ""
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {items[currentIndex]?.type === "video" && (
          <video
            ref={videoRef}
            autoPlay
            muted
            src={items[currentIndex].src}
            onEnded={handleVideoEnded}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              zIndex: -1,
            }}
          />
        )}

        <div
          className="carousel-overlay"
          style={{
            background: `rgba(0, 0, 0, ${items[currentIndex].transparency})`,
          }}
        >
          {items[currentIndex].content}
        </div>
      </div>

      <div className="carousel-indicators">
        {items.map((_, index) => (
          <div
            key={index}
            className={`carousel-indicator ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
