import React, { useState } from "react";
import "./Home.css";
import Carousel from "../../components/carousel/Carousel";
import Carousel2 from "../../components/carousel2/Carousel2";
import useHome from "./useHome";

const Home = () => {
  const { items, items2, items3 } = useHome();
  return (
    <div className="container">
      <div className="home-carousel">
        <Carousel items={items} />
      </div>
      <div className="agriculture-reimagined">
        <h3>Agriculture. Reimagined.</h3>
        <div className="carousel2-home-container">
          <Carousel2 items={items2} />
        </div>
      </div>
      <div className="infocus-section">
        <h3>In focus</h3>
        <div className="in-focus">
          <div className="left-item">
            <h3>Latest</h3>
            {items3.map((item, index) => (
              <div key={index} className="latest-items">
                <p className="date">{item.date}</p>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.description}
                </a>
                <hr />
              </div>
            ))}
            <h3>View All</h3>
          </div>
          <div className="middle-item">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="right-item"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
