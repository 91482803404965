import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Dropdown.css";
import useIcons from "../icons/icons";
import { useLocation } from "react-router-dom";

const Dropdown = ({
  options,
  placeholder,
  onSelect,
  initialSelected,
  style,
  isHeaderDropdownProp,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialSelected || null);
  const [isDropdownAbove, setIsDropdownAbove] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownHeaderRef = useRef(null);
  const isHeaderDropdown = isHeaderDropdownProp || false;

  useEffect(() => {
    if (initialSelected && initialSelected !== selectedOption) {
      setSelectedOption(initialSelected);
      onSelect(initialSelected);
    }
  }, [initialSelected, selectedOption, onSelect]);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="dropdown"
      style={style}
      ref={dropdownRef}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`dropdown-header`}
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-controls="dropdown-list"
        ref={dropdownHeaderRef}
        onMouseEnter={handleMouseEnter}
      >
        {placeholder}
          <span className="dropdown-icon">
            {isOpen ? <useIcons.upArrow /> : <useIcons.downArrow />}
          </span>
      </div>
      {isOpen && (
        <div
          id="dropdown-list"
          className={`dropdown-list ${isDropdownAbove ? "above" : "below"}`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={`dropdown-item ${
                selectedOption?.value === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  initialSelected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  isHeaderDropdownProp: PropTypes.bool,
};

Dropdown.defaultProps = {
  placeholder: "Select...",
  initialSelected: null,
  isHeaderDropdownProp: false,
};

export default Dropdown;
